import styled from '@emotion/styled';
import type { JSX } from 'react';

const ContentWrapper = styled.div`
  h3 {
    font-size: 1em;
  }
`;

const ReleaseNotesHeading = styled.div`
  h2 {
    margin: 0;
    font-size: 1em;
  }

  p {
    margin: 0;
  }
`;

const ReleaseNotesContent = (): JSX.Element => {
  return (
    <ContentWrapper>
      <ReleaseNotesHeading>
        <h2>IoT Service Portal 1.13.0</h2>
        <p>2024-12-03</p>
      </ReleaseNotesHeading>
      <h3>Features</h3>
      <ul>
        <li>
          Disable &quot;Jump to App&quot; when mower isn&apos;t connected to a
          customer
        </li>
        <li>Prevent add mower to account if already fleet managed</li>
      </ul>
      <h3>General Maintenance</h3>
      <ul>
        <li>Consistency for Fleetmanagement</li>
        <ul>
          <li>
            Identify who approved the fleet claiming (none, fleet manager,
            customer)
          </li>
          <li>Show fleet management status if not connected to a customer</li>
          <li>
            Show fleet management status if not approved by fleet manager or
            customer
          </li>
          <li>Removal of redundant Fleetmanagement info in Settings Card</li>
        </ul>
        <li>
          Removal of &quot;triggered by&quot; in Overall State of the Device
          Status Card
        </li>
      </ul>
      <h3>Bug Fixes</h3>
      <ul>
        <li>Device Search in Customer Management doesn&apos;t work for IA02</li>
        <li>
          Jump to app while another writing request is opened (in another role)
        </li>
        <li>Fix MY iMOW® Backend health check</li>
      </ul>
    </ContentWrapper>
  );
};

export default ReleaseNotesContent;
